<template>
  <div >
    <el-row>
      <el-button style="float: left" size="mini" type="primary" icon="el-icon-plus" @click="handleAdd" >
        {{ $t('CreateOperator') }}
      </el-button>
      <!--篩選區塊-->
      <!--<el-divider content-position="right"> {{$t('FilterConditions')}} </el-divider> -->
      <div style="float: right;">
        <el-select v-model="listQuery.enabled" v-bind:placeholder="$t('EnabledStatus')" size="small" class="filter-item" >
          <el-option v-bind:label="$t('Enable')" :value="true" />
          <el-option v-bind:label="$t('Disable')" :value="false" />
        </el-select>
        <el-input size="small" v-model="listQuery.name" v-bind:placeholder="$t('QueryNameOrCode')" class="filter-item" clearable style="width: 180px;"/>
      </div>
    </el-row>
    <el-row style="text-align: left; font-size: .8em; padding-right: 10px; color: dimgrey; padding-left: 10px; margin-bottom: 5px;" >
      <span >產製時間: {{ reportDate }} </span>
    </el-row>
    <!-- table -->
    <el-table  v-loading="listLoading" :data="list" style="width: 100%;" :cell-style="{padding: '0', height: '40px'}" @sort-change="tableSortChange">

      <el-table-column align="center" prop="name" :label="$t('Name')" sortable="custom" />
      <el-table-column align="center" prop="code" :label="$t('Code')" sortable="custom" />
      <el-table-column prop="enabled" :label="$t('EnabledStatus')" align="center">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.enabled" type="success">{{$t('Enabled')}}</el-tag>
          <el-tag v-else type="danger">{{$t('NotEnabled')}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="enabledOCPI" :label="$t('OCPIEnabledStatus')" align="center">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.enabledOCPI" type="success">{{$t('Enabled')}}</el-tag>
          <el-tag v-else type="danger">{{$t('NotEnabled')}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="companyName" :label="$t('Company')"  />
      <el-table-column align="center" prop="contact" :label="$t('Contact')" />
      <!--
      <el-table-column align="center" prop="contactMail" label="聯絡信箱" />
      <el-table-column align="center" prop="contactPhone" label="聯絡電話" />
      -->
      <el-table-column align="center" prop="updateTime" :label="$t('Update')+$t('Date')" sortable="custom" :show-overflow-tooltip="true" >
        <template slot-scope="scope">
          <div :title="scope.row.updateTime">{{ parseDateTime(scope.row.updateTime) }}</div>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="createTime" :label="$t('Create')+$t('Date')" sortable="custom" :show-overflow-tooltip="true" >
        <template slot-scope="scope">
          <div :title="scope.row.createTime">{{ parseDateTime(scope.row.createTime) }}</div>
        </template>
      </el-table-column>
      <el-table-column align="center" :label="$t('Operate')" width="130px" >
        <template slot-scope="scope">
          <el-button type="success" :title="$t('Edit')" size="mini" icon="el-icon-setting" @click="showSetting(scope.row)" />
          <el-button type="danger" :title="$t('Delete')" size="mini" icon="el-icon-delete" @click="handleDelete(scope.$index, scope.row)" />
        </template>
      </el-table-column>

    </el-table>
    <!--分頁組件-->
    <pagination v-show="total>0" :total="total" :current.sync="paginationSelectedPage" :size.sync="listQuery.size" @pagination="getList" />

    <ServiceSetting :dialogVisible="dialogSettingVisible" :groupData="groupData" @close="dialogSettingOnClose()" />
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import { mixins } from '@/views/common/mixins.js'
import ServiceSetting from './ServiceSetting.vue'
import {refreshToken,stopRefreshToken} from "@/utils/auth";
import {apiUrl} from "@/utils/main";

export default {
  name: 'Service',
  components: {
    Pagination,
    ServiceSetting
  },
  mixins: [mixins],
  data() {
    return {
      dialogWidth: 0,
      total: 0,
      list: [],
      listLoading: false,
      listQuery: {
        type: 'general',
        enabled: true,
        name: undefined,
        page: 0, // jpa pageIndex起始從0開始，但Pagination從1開始，透過watch paginationSelectedPage同步
        size: 15
      },
      paginationSelectedPage: 1, // 需配合Pagination元件與listQuery使用
      dialogSettingVisible: false,
      groupData: undefined, // 群組資訊
      dialogPreviewVisible: false,
      previewGroupId: undefined, // 預覽群組ID
      profileGroupType: 'general',
      reportDate: this.formatCurrentDateTime()
    };
  },
  watch: {
    paginationSelectedPage: {
      handler: function(newValue) {
        this.listQuery.page = newValue - 1
      }
    },
    'listQuery.enabled': function() {
      this.listQuery.page = 0
      this.resetPage(this.getList)
    },
    'listQuery.name': function() {
      this.listQuery.page = 0
      this.resetPage(this.getList)
    },
  },
  async created() {
    this.dialogWidth = this.setDialogWidth()
    // await this.$store.dispatch("getUnitTable")
    this.resetPage(this.getList)
    //await refreshToken();

  },
  beforeDestroy(){
    console.log("call beforeDestroy");
    //stopRefreshToken();
  },
  mounted() {
    window.onresize = () => {
      return (() => {
        this.dialogWidth = this.setDialogWidth()
      })()
    }
  },
  methods: {
    getList() {
      this.listLoading = true
      this.axios.get('/api/v1/service', {params: this.listQuery}).then(res=> {
        this.list = res.data.content
        // console.log(this.list)
        this.total = res.data.totalElements
        this.listLoading = false

        this.reportDate = this.formatCurrentDateTime()
      })
    },
    handleAdd() {
      //Modified by Lillian (20241024)
      //this.dialogSettingVisible = true
      let hadCreated = false;
      if(!hadCreated) {
        this.dialogSettingVisible = true
      } else {
        this.$message({
          showClose: true,
          message:  this.$t('WrongK2Code'),
          type: 'error'
        });
      }
    },
    handleDelete(index, row) {
      this.$confirm(this.$t('Hint'), {
        message: this.$t('ConfirmDeleteOperator') +' - ' + row.name + '('+row.code+') ?',
        confirmButtonText: this.$t('Confirm'),
        cancelButtonText: this.$t('Cancel'),
        type: 'warning'
      }).then(() => {
        this.delService(index, row.code)
      }).catch(()=> {
        console.log('cancel delete service')
      })
    },
    async delService(index, code) {
      this.axios.delete('/api/v1/service/' + code).then(()=> {
        this.$delete(this.list, index);
        this.$message({
          showClose: true,
          message: this.$t('Delete') + this.$t('Success'),
          type: 'success'
        })
      }).catch(err => {
        console.log('err:' + err)
        this.$message({
          message: this.$t('Delete') + this.$t('Fail'),
          type: 'error'
        })
      })
    },
    showSetting(row) {
      // console.log(row)
      this.dialogSettingVisible = true
      this.groupData = row
    },
    dialogSettingOnClose() {
      this.dialogSettingVisible = false
      this.groupData = undefined
      this.resetPage(this.getList)
    },
    dialogPreviewOnClose() {
      this.dialogPreviewVisible = false
      this.previewGroupId = undefined
      this.resetPage(this.getList)
    }
  }
}
</script>

