<template>
    <el-dialog width="600" :title="$t('OperatorSettings')" :visible="dialogVisible" @close="dialogDataOnClose()" @open="dialogDataOnOpen()">
      <el-tabs v-model="activeTabPaneName" class="user-tabs">
        <el-tab-pane label="基本資料" name="basic-data" style="padding: 0px 10px;">
          <el-form ref="dataForm" :rules="rules" :model="updateQuery" size="small">
            <el-row :gutter="20">
              <el-col :xs="22" :sm="22" :md="22" :lg="11" :xl="11">
                <el-form-item prop="name" :label="$t('OperatorName')" >
                  <el-input id="name" size="small" v-model="updateQuery.name" :placeholder="$t('EnterOperatorName')" class="filter-item"
                            clearable style="width: 95%"/>
                </el-form-item>
              </el-col>

              <el-col :xs="22" :sm="22" :md="22" :lg="11" :xl="11">
                <el-form-item prop="code" :label="$t('OperatorCode')" >
                  <el-input id="code" size="small" v-model="updateQuery.code" :placeholder="$t('EnterOperatorCode')" class="filter-item"
                            clearable style="width: 95%" v-bind:readonly="isModify" :disabled="isModify" />
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="20">
              <el-col :xs="22" :sm="22" :md="22" :lg="11" :xl="11">
                <el-form-item prop="companyName" :label="$t('CompanyName')" >
                  <el-input id="companyName" size="small" v-model="updateQuery.companyName" :placeholder="$t('EnterCompanyName')" class="filter-item"
                            clearable style="width: 95%" />
                </el-form-item>
              </el-col>

              <el-col :xs="22" :sm="22" :md="22" :lg="11" :xl="11">
                <el-form-item prop="companyEngName" :label="$t('CompanyEngName')" >
                  <el-input id="companyEngName" size="small" v-model="updateQuery.companyEngName" :placeholder="$t('EnterCompanyEngName')" class="filter-item"
                            clearable style="width: 95%"/>
                </el-form-item>
              </el-col>

            </el-row>

            <el-row :gutter="20">
              <el-col :xs="22" :sm="22" :md="22" :lg="11" :xl="11">
                <el-form-item prop="contact" :label="$t('Contact')" >
                  <el-input id="contact" size="small" v-model="updateQuery.contact" :placeholder="$t('EnterContact')" class="filter-item"
                            clearable style="width: 95%"/>
                </el-form-item>
              </el-col>

              <el-col :xs="22" :sm="22" :md="22" :lg="11" :xl="11">
                <el-form-item prop="contactMail" :label="$t('ContactMail')" >
                  <el-input id="contactMail" size="small" v-model="updateQuery.contactMail" :placeholder="$t('EnterContactMail')" class="filter-item"
                            clearable style="width: 95%"/>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row :gutter="20">
              <el-col :xs="22" :sm="22" :md="22" :lg="11" :xl="11">
                <el-form-item prop="contactPhone" :label="$t('ContactPhone')">
                  <el-input id="contactPhone" size="small" v-model="updateQuery.contactPhone" :placeholder="$t('EnterContactPhone')" class="filter-item" clearable style="width: 95%"/>
                </el-form-item>
              </el-col>

              <el-col :xs="22" :sm="22" :md="22" :lg="11" :xl="11">
                <el-form-item prop="ban" :label="$t('Ban')">
                  <el-input id="ban" size="small" v-model="updateQuery.ban" :placeholder="$t('EnterBan')" class="filter-item"  clearable style="width: 95%"/>
                </el-form-item>
              </el-col>

            </el-row>

            <el-row :gutter="20">
              <el-col :xs="22" :sm="22" :md="22" :lg="22" :xl="22">
                <el-form-item prop="contactAddress" :label="$t('ContactAddress')">
                  <el-input id="contactAddress" size="small" v-model="updateQuery.contactAddress" :placeholder="$t('EnterContactAddress')" class="filter-item"
                            clearable style="width: 95%"/>
                </el-form-item>
              </el-col>
              <el-col :xs="22" :sm="22" :md="22" :lg="22" :xl="22">
                <el-form-item prop="comapnyWebUrl" :label="$t('CompanyWebUrl')">
                  <el-input id="comapnyWebUrl" size="small" v-model="updateQuery.companyWebUrl" :placeholder="$t('EnterCompanyWebUrl')" class="filter-item"
                            clearable style="width: 95%"/>
                </el-form-item>
              </el-col>
              <el-col :xs="22" :sm="22" :md="22" :lg="22" :xl="22">
                <el-form-item prop="companyLogoUrl" :label="$t('CompanyLogoUrl')" >
                  <el-input id="companyLogoUrl" size="small" v-model="updateQuery.companyLogoUrl" :placeholder="$t('EnterCompanyLogoUrl')" class="filter-item"
                            clearable style="width: 95%"/>
                </el-form-item>
              </el-col>
              <el-col :xs="22" :sm="22" :md="22" :lg="22" :xl="22">
                <el-form-item prop="companyTsAndCsURL" :label="$t('CompanyTsAndCsURL')" >
                  <el-input id="companyTsAndCsURL" size="small" v-model="updateQuery.companyTsAndCsURL" :placeholder="$t('EnterCompanyTsAndCsURL')" class="filter-item"
                            clearable style="width: 95%"/>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :xs="22" :sm="22" :md="22" :lg="11" :xl="11">
                <el-form-item prop="enabled" label="中華電信發票" title="是否開立中華電信發票">
                  <el-select id="enabled" v-model="updateQuery.enabledCHTInvoice" placeholder="是否開立中華電信發票" size="small" class="filter-item" style="width: 95%" >
                    <el-option :label="$t('Enable')" :value="true" />
                    <el-option :label="$t('Disable')" :value="false" />
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20" v-if="updateQuery.enabledCHTInvoice == true" >
              <el-col :xs="22" :sm="22" :md="22" :lg="11" :xl="11">
                <el-form-item prop="serviceName" label="服務名稱" >
                  <el-input id="serviceName" size="small" v-model="updateQuery.serviceName" placeholder="請輸入中華電信發票服務名稱" class="filter-item" maxlength="8"
                            clearable style="width: 95%"/>
                </el-form-item>
              </el-col>
              <el-col :xs="22" :sm="22" :md="22" :lg="11" :xl="11">
                <el-form-item prop="serviceType" label="服務總類" >
                  <el-input id="serviceType" size="small" v-model="updateQuery.serviceType" placeholder="請輸入中華電信發票服務種類" class="filter-item" maxlength="2"
                            clearable style="width: 95%"/>
                </el-form-item>
              </el-col>
              <el-col :xs="22" :sm="22" :md="22" :lg="11" :xl="11">
                <el-form-item prop="contractSN" label="合約編碼" > <!--Added by Lillian(20241024):為了避免專標案有相同的sN/sT, 利用合約編碼作為區別.-->
                  <el-input id="contractSN" size="small" v-model="updateQuery.contractSN" placeholder="請輸入合約編碼(如:D09601C001-01)" class="filter-item" maxlength="13"
                            clearable style="width: 95%"/>
                </el-form-item>
              </el-col>
              <el-col :xs="22" :sm="22" :md="22" :lg="11" :xl="11">
                <el-form-item prop="k2code" label="K2會計契約碼" > <!--Added by Lillian(20241023)-->
                  <el-input id="k2code" size="small" v-model="updateQuery.k2code" placeholder="請輸入K2碼(如:4DA120715H)" class="filter-item" maxlength="10"
                              clearable style="width: 95%"/>
                </el-form-item>
              </el-col>
              <el-col :xs="22" :sm="22" :md="22" :lg="11" :xl="11">
                <!--Noted by Lillian(20241024):等RA系統串接完成, 可移除合約起訖日, 由系統從ra_info直接撈取並填入, 減少人為輸入錯誤.-->
                <el-form-item prop="contractstdt" label="合約起始日期" >
                  <el-input id="contractstdt" size="small" v-model="updateQuery.contractstdt" placeholder="請輸入合約起始日期(如:1130101)" class="filter-item" maxlength="7"
                            clearable style="width: 95%"/>
                </el-form-item>
              </el-col>
              <el-col :xs="22" :sm="22" :md="22" :lg="11" :xl="11">
                <el-form-item prop="contractftdt" label="合約結束日期" >
                  <el-input id="contractftdt" size="small" v-model="updateQuery.contractftdt" placeholder="請輸入合約結束日期(如:1131231)" class="filter-item" maxlength="7"
                            clearable style="width: 95%"/>
                </el-form-item>
              </el-col>

            </el-row>
            <el-row :gutter="20">
              <el-col :xs="22" :sm="22" :md="22" :lg="22" :xl="22">
                <el-form-item prop="productMail" label="產品單位信箱" >
                  <el-input id="productMail" size="small" v-model="updateQuery.productMail" placeholder="請輸入產品單位信箱，允許多個信箱，以分號區隔" class="filter-item"
                            clearable style="width: 95%"/>
                </el-form-item>
              </el-col>
              <el-col :xs="22" :sm="22" :md="22" :lg="22" :xl="22">
                <el-form-item prop="accountingMail" label="帳服單位信箱" >
                  <el-input id="accountingMail" size="small" v-model="updateQuery.accountingMail" placeholder="請輸入帳務單位信箱，允許多個信箱，以分號區隔" class="filter-item"
                            clearable style="width: 95%"/>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :xs="22" :sm="22" :md="22" :lg="11" :xl="11">
                <el-form-item prop="enabled" :label="$t('Status')">
                  <el-select id="enabled" v-model="updateQuery.enabled" :placeholder="$t('WhetherToEnable')" size="small" class="filter-item" style="width: 95%" >
                    <el-option :label="$t('Enable')" :value="true" />
                    <el-option :label="$t('Disable')" :value="false" />
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :xs="22" >
                <div style="margin-bottom: 20px;">{{$t('ServiceSettingNote')}}</div>
              </el-col>
            </el-row>
          </el-form>
        </el-tab-pane>

        <el-tab-pane label="OCPI功能" name="ocpi-setting" style="padding: 0px 10px;">
<!--          <el-divider content-position="left">設定</el-divider>-->
          <el-row :gutter="20">
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" style="text-align: left; padding-top: 10px;">
              <el-switch
                  v-model="updateQuery.enabledOCPI"
                  active-text="開啟"
                  active-color="#13ce66"
                  inactive-text="關閉"
                  inactive-color="#bfbbbb"
                  style="margin-bottom: 10px">
              </el-switch>
            </el-col>
          </el-row>
          <el-card class="box-card">
            <el-form ref="ocpiDataForm" :rules="ocpirules" :model="updateQuery" :disabled="!updateQuery.enabledOCPI" size="small">
              <el-row :gutter="20">
                <el-col :xs="22" :sm="22" :md="22" :lg="11" :xl="11">
                  <el-form-item :prop="updateQuery.enabledOCPI?'ocpiPartyId':null" :key="updateQuery.enabledOCPI+1" :label="$t('OCPIPartyId')" >
                    <el-input id="ocpiPartyId" size="small"  v-model="updateQuery.ocpiPartyId" :placeholder="$t('EnterOCPIPartyID')" class="filter-item"
                              clearable style="width: 95%"/>
                  </el-form-item>
                </el-col>

                <el-col :xs="22" :sm="22" :md="22" :lg="11" :xl="11">
                  <el-form-item :prop="updateQuery.enabledOCPI?'ocpiCountryCode':null" :key="updateQuery.enabledOCPI+2" :label="$t('OCPICountryCode')" >
                    <el-input id="ocpiCountryCode" size="small"  v-model="updateQuery.ocpiCountryCode" :placeholder="$t('EnterOCPICountryCode')" class="filter-item"
                              clearable style="width: 95%"/>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row :gutter="20">
                <el-col :xs="22" :sm="22" :md="22" :lg="11" :xl="11">
                  <el-form-item :prop="updateQuery.enabledOCPI?'ocpiVersionId':null" :key="updateQuery.enabledOCPI+3" :label="$t('OCPIVersionId')" title="OCPI版本號碼">
                    <el-select id="ocpiVersionId"  v-model="updateQuery.ocpiVersionId" placeholder="OCPI版本號碼" size="small" class="filter-item" style="width: 95%" >
                      <el-option label="2.2.1" value="2.2.1" />
                    </el-select>
                  </el-form-item>
<!--                  <el-form-item prop="versionId" :label="$t('OCPIVersionId')" >-->
<!--                    <el-input id="versionId" size="small" :disabled="!this.ocpiOnOff" v-model="updateQuery.companyName" :placeholder="$t('EnterOCPIVersionId')" class="filter-item"-->
<!--                              clearable style="width: 95%" />-->
<!--                  </el-form-item>-->
                </el-col>
              </el-row>

              <el-row :gutter="20">
                <el-col :xs="22" :sm="22" :md="22" :lg="22" :xl="22">
                  <el-form-item :prop="updateQuery.enabledOCPI?'ocpiVersionEndPointUrl':null" :key="updateQuery.enabledOCPI+4"  :label="$t('OCPIVersionEndPointUrl')">
                    <el-input id="ocpiVersionEndPointUrl" size="small"  v-model="updateQuery.ocpiVersionEndPointUrl" :placeholder="$t('EnterOCPIVersionEndPointUrl')" class="filter-item"
                              clearable style="width: 90%">
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :xs="22" :sm="22" :md="22" :lg="20" :xl="22">
                  <el-form-item :prop="updateQuery.enabledOCPI?'ocpiCredentialToken':null" :key="updateQuery.enabledOCPI+5"  :label="$t('OCPICredentialToken')">
                    <el-input id="ocpiCredentialToken" size="small"  v-model="updateQuery.ocpiCredentialToken" :placeholder="$t('OCPICredentialToken')" class="input-with-select"
                              clearable style="width: 70%">
                      <el-button slot="append" icon="el-icon-thumb" type="info" style="color: white;background-color:#7AC5CD" @click="generateRandomToken">點擊產生</el-button>
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :xs="22" :sm="22" :md="22" :lg="22" :xl="22">
                  <el-form-item prop="partnerCredentailToken"  :label="$t('OCPIPartnerCredentialToken')" >
                    <el-input id="partnerCredentailToken" size="small"  v-model="updateQuery.ocpiPartnerCredentialToken" :placeholder="$t('OCPIPartnerCredentialToken')" class="filter-item"
                              clearable style="width: 90%"/>
                  </el-form-item>
                </el-col>
              </el-row>

            </el-form>
          </el-card>
        </el-tab-pane>

      </el-tabs>
        <el-button style="margin-bottom: 0px; margin-top: 10px;" size="median" type="primary"  @click="updateData()">{{$t('Save')}}</el-button>

        <br/><br/>
    </el-dialog>
</template>

<script>
import { mixins } from '@/views/common/mixins.js'
import * as crypto from "crypto"

export default {
    name: 'ServiceSetting',
    mixins: [mixins],
    props: { //一個元件需要明確地宣告它所接受的props，這樣Vue 才能知道外部傳入的哪些是props
        groupData: {
            require: false,
            default: undefined,
            type: Object
        },
        dialogVisible: {
            require: true,
            default: undefined,
            type: Boolean
        }
    },
    data() {
        let mailValid = (rule, value, callback) =>{
            if(/^[^@]+@\w+(\.\w+)+\w$/.test(value)){
                callback();
            }else{
                callback(new Error(this.$t('IncorrectMail')));
            }

        };
        let codeValid = (rule, value, callback) =>{
            if(/^[A-Z0-9_-]{3,}$/.test(value)){
                callback();
            }else{
                callback(new Error(this.$t('IncorrectCode')));
            }

        };
        let phoneValid = (rule, value, callback) => {
            if( value == null || value.length == 0 ){
                callback(new Error("請輸入8碼以上的電話號碼(支援數字與-符號)"));
            } else if (/^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{2,3}\)?[\s.-]?\d{3}[\s.-]?\d{2,}$/.test(value)) {
                callback();
            } else {
                callback(new Error("請輸入8碼以上的電話號碼(支援數字與-符號)"));
            }

        };
        let banValid = (rule, value, callback) => {
            if (/^[0-9]{8}$/.test(value)) {
                if(this.uniformNumbers_verification(value)) {
                    callback();
                } else {
                    callback(new Error("統一編號格式錯誤(營利事業統一編號檢查碼錯誤)"))
                }

            } else {
                callback(new Error("統一編號格式錯誤，8碼"));
            }

        };
        let contractstdtValid = (rule, value, callback) => {
            if (/^[0-9]{7}$/.test(value)) {
                callback();
            } else {
                callback(new Error("日期格式錯誤，7碼(1130101)"));
            }
        };
        let contractftdtValid = (rule, value, callback) => {
            if (/^[0-9]{7}$/.test(value)) {
                callback();
            } else {
                callback(new Error("日期格式錯誤，7碼(1130101)"));
            }
        };
        let multiMailsValid = (rule, value, callback) =>{
            if( value == null || value.length == 0 ){
                callback();
            } else {
                if(/^[\W]*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4}[\W]*;{1}[\W]*)*([\w+\-.%]+@[\w\-.]+\.[A-Za-z]{2,4})[\W]*$/.test(value)){
                    callback();
                }else{
                    callback(new Error(this.$t('IncorrectMail')));
                }
            }

        };
        let partyIdValid = (rule, value, callback) =>{
          if(/^[A-Z0-9_-]{1,3}$/.test(value)){
            callback();
          }else{
            callback(new Error(this.$t('IncorrectCode')));
          }

        };
        let countryCodeValid = (rule, value, callback) =>{
          if(/^[A-Z0-9_-]{1,2}$/.test(value)){
            callback();
          }else{
            callback(new Error(this.$t('IncorrectCode')));
          }

        };
        let k2codeValid = (rule, value, callback) =>{
          if(value.length == 10){
            callback();
          }else{
            callback(new Error("請輸入共10碼的資料(且最後一碼為H)"));
          }

        };
        let contractSNValid = (rule, value, callback) =>{
          if(value.length == 13){
            callback();
          }else{
            callback(new Error("請輸入共13碼的資料(如D09601C001-01)"));
          }

        };
        return {
            activeTabPaneName: "basic-data",
            dialogWidth: 0,
            total: 0,
            list: [],
            listLoading: false,
            isModify: false,
            updateQuery: {
                id: undefined,
                name: undefined,
                code: undefined,
                companyName: undefined,
                companyEngName: undefined,
                ban: undefined,
                contact: undefined,
                contactMail: undefined,
                contactPhone: undefined,
                contactAddress: undefined,
                companyWebUrl: undefined,
                companyLogoUrl: undefined,
                companyTsAndCsURL: undefined,
                enabledCHTInvoice: undefined,
                serviceName: undefined,
                serviceType: undefined,
                contractstdt: undefined,
                contractftdt: undefined,
                productMail: undefined,
                accountingMail: undefined,
                enabled: true,
                k2code: undefined, //Added by Lillian(20241023)
                contractSN: undefined, //Added by Lillian(20241024)

                enabledOCPI:false,
                ocpiPartyId: undefined,
                ocpiCountryCode: "TW",
                ocpiVersionId: "2.2.1",
                ocpiVersionEndPointUrl: undefined,
                ocpiCredentialToken: undefined,
                ocpiPartnerCredentialToken: undefined,



            },

            rules: {
                enabled: [{ required: true, message: '('+this.$t('Required')+')', trigger: 'blur' }],
                name: [{ required: true, message: '('+this.$t('Required')+')', trigger: 'blur' }],
                companyName: [{ required: true, message: '('+this.$t('Required')+')', trigger: 'blur' }],
                ban: [{ required: true, validator: banValid, trigger: 'blur' }],
                contactPhone: [{ required: true, validator: phoneValid, trigger: 'blur' }],
                contactAddress: [{ required: true, message: '('+this.$t('Required')+')', trigger: 'blur' }],
                code: [{ required: true, validator: codeValid, trigger: 'blur' }] ,
                contact: [{ required: true, message: '('+this.$t('Required')+')', trigger: 'blur' }],
                contactMail: [{ required: true,  validator: mailValid, trigger: 'blur' }],
                serviceName: [{ required: false,  message: '('+this.$t('Required')+')', trigger: 'blur' }],
                serviceType: [{ required: false,  message: '('+this.$t('Required')+')', trigger: 'blur' }],
                k2code: [{ required: false,  validator: k2codeValid, trigger: 'blur' }], //Added by Lillian (20241023):後面才設定required=true
                contractSN: [{ required: false,  validator: contractSNValid, trigger: 'blur' }], //Added by Lillian (20241023):後面才設定required=true
                contractstdt: [{ required: false,  validator: contractstdtValid, trigger: 'blur' }],
                contractftdt: [{ required: false,  validator: contractftdtValid, trigger: 'blur' }],
                productMail: [{ required: false,  validator: multiMailsValid, trigger: 'blur' }],
                accountingMail: [{ required: false,  validator: multiMailsValid, trigger: 'blur' }],
            },

            ocpirules: {
                ocpiPartyId: [{ required: true, validator: partyIdValid, trigger: 'blur' }] ,
                ocpiCountryCode: [{ required: true, validator: countryCodeValid, trigger: 'blur' }] ,
                ocpiVersionId: [{ required: true,  message: '('+this.$t('Required')+')', trigger: 'blur' }],
                ocpiVersionEndPointUrl: [{ required: true,  message: '('+this.$t('Required')+')', trigger: 'blur' }],
                ocpiCredentialToken: [{ required: true,  message: '('+this.$t('Required')+')', trigger: 'blur' }],
            },
        };
    },
    watch: {
        groupData(val) {
            if (val) {
                console.log(val);
                this.isModify = true
                this.updateQuery.id = val.id
                this.updateQuery.name = val.name
                this.updateQuery.code = val.code
                this.updateQuery.companyName = val.companyName
                this.updateQuery.companyEngName = val.companyEngName
                this.updateQuery.contact = val.contact
                this.updateQuery.contactMail = val.contactMail
                this.updateQuery.contactPhone = val.contactPhone
                this.updateQuery.contactAddress = val.contactAddress
                this.updateQuery.ban = val.ban
                this.updateQuery.companyWebUrl = val.companyWebUrl
                this.updateQuery.companyLogoUrl = val.companyLogoUrl
                this.updateQuery.companyTsAndCsURL = val.companyTsAndCsURL
                this.updateQuery.enabledCHTInvoice = val.enabledCHTInvoice
                this.updateQuery.serviceName = val.serviceName
                this.updateQuery.serviceType = val.serviceType
                this.updateQuery.contractstdt = val.contractstdt
                this.updateQuery.contractftdt = val.contractftdt
                this.updateQuery.productMail = val.productMail
                this.updateQuery.accountingMail = val.accountingMail
                this.updateQuery.enabled = val.enabled
                this.updateQuery.enabledOCPI = val.enabledOCPI
                this.updateQuery.ocpiPartyId = val.ocpiPartyId
                this.updateQuery.ocpiCountryCode = val.ocpiCountryCode
                this.updateQuery.ocpiVersionId = val.ocpiVersionId
                this.updateQuery.ocpiVersionEndPointUrl = val.ocpiVersionEndPointUrl
                this.updateQuery.ocpiCredentialToken = val.ocpiCredentialToken
                this.updateQuery.ocpiPartnerCredentialToken = val.ocpiPartnerCredentialToken
                this.updateQuery.k2code = val.k2code  //Added by Lillian (20241023)
                this.updateQuery.contractSN = val.contractSN  //Added by Lillian (20241024)
            } else {
                this.resetUpdateQuery()
            }
        },
        'updateQuery.code': {
            handler(val, oldVal) {
                if(val != undefined)
                    this.updateQuery.code = val.toUpperCase()
            },
            deep: true
        },
        'updateQuery.enabledCHTInvoice': function () {
            if(this.updateQuery.enabledCHTInvoice == false) {
                this.rules.serviceName[0].required = false
                this.rules.serviceType[0].required = false
                this.rules.contractstdt[0].required = false
                this.rules.contractftdt[0].required = false
                this.rules.k2code[0].required = false //Added by Lillian (20241023)
                this.rules.contractSN[0].required = false //Added by Lillian (20241024)
            } else {
                this.rules.serviceName[0].required = true
                this.rules.serviceType[0].required = true
                this.rules.contractstdt[0].required = true
                this.rules.contractftdt[0].required = true
                this.rules.k2code[0].required = true //Added by Lillian (20241023)
                this.rules.contractSN[0].required = true //Added by Lillian (20241024)
            }
        }

    },
    async created() {
    },
    methods: {
        dialogDataOnClose() {
            this.resetUpdateQuery()
            this.$refs.dataForm.resetFields() // 表單校驗結果重置
            this.$refs.ocpiDataForm.resetFields() //ocpi表單校驗結果重置
            this.$emit('close') // 觸發父層 close
        },
        // 一般篩選條件上傳
        updateData() {

          //同時檢驗「基本資料」與「OCPI功能」兩表單
          let formValidates = [
            this.$refs['ocpiDataForm'].validate(),
            this.$refs['dataForm'].validate(),
          ]
          Promise.all(formValidates).then(()=>{
            // update
            this.axios.put('/api/v1/service', this.updateQuery).then(() => {
              this.$message({
                showClose: true,
                message: this.$t('SuccessEdited'),
                type: 'success'
              })
              this.dialogDataOnClose()
            }).catch(err => {
              console.log('err:' + err)
              if(err.data.includes('WrongK2Code')) { //Added by Lillian (20241024)
                this.$message({
                   message: this.$t('WrongK2Code'),
                   type: 'error'
                })
              } else {
                this.$message({
                   message: this.$t('FailEdited') + ' ('+err.data+')' ,
                   type: 'error'
                })
              }
            })

          }).catch(err =>{
            this.$message({
              message: this.$t('IncorrectFormat'),
              type: 'error'
            })
          })

            // this.$refs['dataForm'].validate((valid) => {
            //     if (valid) {
            //         // update
            //         this.axios.put('/api/v1/service', this.updateQuery).then(() => {
            //             this.$message({
            //                 showClose: true,
            //                 message: this.$t('SuccessEdited'),
            //                 type: 'success'
            //             })
            //             this.dialogDataOnClose()
            //         }).catch(err => {
            //             console.log('err:' + err)
            //             this.$message({
            //                 message: this.$t('FailEdited') + ' ('+err.data+')' ,
            //                 type: 'error'
            //             })
            //         })
            //     } else {
            //         this.$message({
            //             message: this.$t('IncorrectFormat'),
            //             type: 'error'
            //         })
            //     }
            // })
        },
        resetUpdateQuery() {
            this.isModify = false
            this.updateQuery = {
                id: undefined,
                name: undefined,
                code: undefined,
                companyName: undefined,
                companyEngName: undefined,
                ban: undefined,
                contact: undefined,
                contactMail: undefined,
                contactPhone: undefined,
                contactAddress: undefined,
                companyWebUrl: undefined,
                companyLogoUrl: undefined,
                companyTsAndCsURL: undefined,
                enabledCHTInvoice: undefined,
                serviceName: undefined,
                serviceType: undefined,
                contractstdt: undefined,
                contractftdt: undefined,
                productMail: undefined,
                accountingMail: undefined,
                enabled: true,
                enabledOCPI:false,
                ocpiPartyId: undefined,
                ocpiCountryCode: "TW",
                ocpiVersionId: "2.2.1",
                ocpiVersionEndPointUrl: undefined,
                ocpiCredentialToken: undefined,
                ocpiPartnerCredentialToken: undefined,
                k2code: undefined, //Added by Lillian (20241023)
                contractSN: undefined, //Added by Lillian (20241024)
            }
            this.rules.serviceName[0].required = true
            this.rules.serviceType[0].required = true
            this.rules.contractstdt[0].required = true
            this.rules.contractftdt[0].required = true
            this.rules.k2code[0].required = true  //Added by Lillian (20241023)
            this.rules.contractSN[0].required = true  //Added by Lillian (20241024)
        },
        uniformNumbers_verification(uniformNumbers) {
            // 檢查字元是否符合規則  04595257 04595252 10458575 10458574 10458570
            let regex = /^[0-9]{8}$/;

            // 統一編號 邏輯乘數
            let logicMultipliers = [1, 2, 1, 2, 1, 2, 4, 1];

            // 計算陣列總和
            let sum = (numbers) =>{
                let initialValue = 0;
                let sumWithInitial = numbers.reduce(
                    (accumulator, currentValue) => Number(accumulator) + Number(currentValue),
                    initialValue,
                );
                return sumWithInitial
            }

            if(!uniformNumbers){
                return false
            }
            if (uniformNumbers.length !== 8 || !regex.test(uniformNumbers)){
                return false
            }

            let logicProductArr = []
            let logicProduct = 0;
            // 通一編號倒數第二位為7時，乘積之和最後第二位數取0或1均可，其中之一和能被5整除，則符合統編邏輯
            if (uniformNumbers[6] == "7") {
                for (let i = 0; i < uniformNumbers.length ; i++) {
                    if (i != 6) {
                        logicProductArr.push(parseInt(uniformNumbers[i]) * logicMultipliers[i])
                    }
                }
            } else {
                for (let i = 0; i < uniformNumbers.length ; i++) {
                    logicProductArr.push(parseInt(uniformNumbers[i]) * logicMultipliers[i]);
                }
            }

            logicProductArr.forEach((item) => logicProduct += sum((item.toString()).split('')))


            if (uniformNumbers[6] === '7' && (logicProduct % 5 === 0 || (logicProduct + 1) % 5 === 0)) {

                return true;
            } else if (logicProduct % 5 === 0) {

                return true;
            }
            return false;
        },
        generateRandomToken(){
          const vm = this;
          //隨機生成24bytes array再經過Base64編碼=32 chars
          //24 x 8 = 192 bits , 192 / 6 = 32. Base64: 6 bits-->1 char
          crypto.randomBytes(24,(err,buf) => {
            if(err){
              console.log(err);
              return;
            }
          vm.updateQuery.ocpiCredentialToken = buf.toString('base64');
            console.log("token="+vm.updateQuery.ocpiCredentialToken);

          })

        },        dialogDataOnOpen(){
        if(!this.isModify)
        {
          this.generateRandomToken();
        }

      },

    }

}
</script>
<style>
.el-form-item__error{
    top: 80%
}


/* Medium devices (tablets, 768px and up) */
@media (max-width: 767.98px) {
    .el-form-item__content {
        text-align: left;
    }
}

@media (min-width: 768px) {
    .el-form-item__content {
        text-align: left;
        margin-left: 120px;
    }

    .el-form-item__label {
        width: 120px;
    }
 }
</style>
